var cookieName  = 'pdf_pages';
var cookie;
var sureReset   = 'Weet u zeker dat u alle pagina\'s uit de selectie wilt verwijderen?';


jQuery(function(){

  pdfInit();
  
});
function pdfInit() {
  
  if (jQuery.cookie(cookieName) == null) {
    cookie = '';
  } else {
    cookie = jQuery.cookie(cookieName);
    if (cookie.indexOf(';') != -1) jQuery('#pdf-selection').removeClass('disabled');
  }
  
  jQuery('#pdf-add').click(function(){
    var nodeInfo = jQuery(this).attr('data-page');
    if (cookie.indexOf(nodeInfo) == -1) {
      cookie += nodeInfo + ';';
      jQuery.cookie(cookieName,cookie,{expires:7,path:'/'});
      jQuery('#pdf-selection, #pdf-remove').removeClass('disabled');
      jQuery(this).addClass('disabled');
    }
  }).each(function(){
    var nodeInfo = jQuery(this).attr('data-page');
    if (cookie.indexOf(nodeInfo) != -1) {
      jQuery('#pdf-selection, #pdf-remove').removeClass('disabled');
      jQuery(this).addClass('disabled');
    }
  });
  
  jQuery('#pdf-remove').click(function(){
    var nodeInfo = jQuery(this).attr('data-page');
    if (cookie.indexOf(nodeInfo) != -1) {
      cookie = cookie.replace(nodeInfo + ';','');
      jQuery.cookie(cookieName,cookie,{expires:7,path:'/'});
      jQuery('#pdf-add').removeClass('disabled');
      jQuery(this).addClass('disabled');
      if (cookie.indexOf(';') == -1) jQuery('#pdf-selection').addClass('disabled');
    }
  });
  
  /*jQuery('#reset').click(function(){
    if (confirm(sureReset)) {
      cookie = '';
      jQuery.cookie(cookieName,cookie,{expires:7,path:'/'});
      jQuery('#add-page').text(jQuery('#add-page').attr('data-add-text'));
      jQuery(this).addClass('disabled');
      jQuery('#selection').addClass('disabled');
    }
  });*/
  
  jQuery('#pdf-selection').click(function(){
    if (cookie.indexOf(';') != -1) {
      var url = jQuery(this).attr('data-urlstart');
      var nodes = cookie.split(';');
      for(var i=0; i<nodes.length-1; i++) {
        url += splitNodeInfo(nodes[i]).id + '-';
      }
      location.href = url;
    }
  });
  
}

function splitNodeInfo(value) {
  var array = value.split('|');
  return {id: array[0], title: array[1], url: array[2]};
}